<template>
    <div>
         <div class="instance-block-1" style="background:rgba(245,245,249,1);">
            <div class="instance-block-1-title">
                開発実績例
            </div>
            <div :style="{borderLeft:'8px solid '+item.borderColor}" v-for="(item, index) in cardData" :key="index" class="instance-block-1-body">
                <div class="instance-block-title">{{item.title}}</div>
                <div class="instance-block-desc">
                    <div>
                        <p v-for="(desc, index) in item.desc" :key="index">
                            {{desc}}
                        </p>
                    </div>
                </div>
                <div style="text-align:right;">
                    <img class="instance-img" :src="item.image" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            cardData:[
                {
                    index:0,
                    title:'金融機関向け',
                    desc:['某銀行向け、次世代システム構築','某銀行向け、PMO業務支援','某銀行向け、海外支店向けシステム開発','某銀行向け、勘定系システム開発'],
                    image:require('@/assets/images/company/img_kaifaanli1.png'),
                    borderColor:'#0262FF'
                },
                {
                    index:1,
                    title:'クレジットカード業務向け',
                    desc:['某クレジットカード会社の経理帳票管理システム開発',
                        '某クレジットカード会社のイシュアシステム移行',
                        '某クレジットカード会社の経営統合システム開発',
                        '基幹システム統合による情報受取加工システム',
                        '某クレジットカード会社の受付与信システム'],
                    image:require('@/assets/images/company/img_kaifaanli2.png'),
                    borderColor:'#FE8F03'
                },
                {
                    index:2,
                    title:'製造・流通業向け',
                    desc: ['会計パッケージのマイグレーション案件',
                        '（VB6.0→VB.Netへ、IFARSも対応）',
                        '某大手商社向けSAP連結決算システム（海外子会社含み、為替処理も担当）',
                        '某流通業向け流通システム',
                        '某流通業向け店舗会計支援システム',
                        '某大手メーカー向けOracle EBS 会計・生産管理システム'],
                    image:require('@/assets/images/company/img_kaifaanli3.png'),
                    borderColor:'#0262FF'
                },
                {
                    index:3,
                    title:'組込関連',
                    desc:['携帯電話　ANDROIDなど',
                        'Linux　カーネル開発',
                        'サーバーミドルウェア',
                        '電子顕微鏡、内視鏡などの医療機器制御'],
                    image:require('@/assets/images/company/img_kaifaanli4.png'),
                    borderColor:'#FE8F03'
                },
                 {
                    index:4,
                    title:'医療分野',
                    desc:['レセプト管理システム',
                        '医療データ分析各種ソリューション'],
                    image:require('@/assets/images/company/img_kaifaanli5.png'),
                    borderColor:'#0262FF'
                },
            ]
        }
    }
}
</script>

<style>
@media screen and (min-width:768px) and (max-width:1920px){
    .instance-block-1{
        padding:0 calc(100vw/1920*360);
        padding-bottom: calc(100vw/1920*130);
    }
    .instance-block-title{
        width:calc(100vw/1920*200);
        border-right:1px solid #DDDDDD;
        padding:0 calc(100vw/1920*30);
        font-size:calc(100vw/1920*18);
        font-family:'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight:700;
        display:flex;
        align-items:center;
        color:rgba(51,51,51,1);
        line-height:calc(100vw/1920*25);
    }
    .instance-block-desc{
        padding:0 20px;
        font-size:calc(100vw/1920*16);
        font-family:'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight:400;
        display:flex;
        align-items:center;
        width:55%;
        text-align:left;
        color:rgba(102,102,102,1);
        line-height:calc(100vw/1920*22);
    }
    .instance-img{
        width:calc(100vw/1920*325);
        height:calc(100vw/1920*200);
    }
    .instance-block-1-title{
        font-size:calc(100vw/1920*24);
        font-family:'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight:700;
        color:rgba(51,51,51,1);
        line-height:calc(100vw/1920*33);
        text-align: left;
        padding-top: calc(100vw/1920*30);
    }
    .instance-block-1-body{
        display: flex;
        width: 100%;
        height: 100%;
        margin-top: calc(100vw/1920*50);
        height:calc(100vw/1920*260);
        padding: calc(100vw/1920*30);
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
        border-left: 8px solid;
    }
}
@media screen and (max-width:768px){
    .instance-block-1{
        padding:20px;
    }
    .instance-block-1-title{
        font-size:14px;
        font-family:'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight:500;
        color:rgb(41, 27, 27);
        line-height:14px;
        text-align: left;
    }
    .instance-block-1-body{
        display: flex;
        width: 100%;
        height: 100%;
        margin-top: 20px;
        /* height:100px; */
        padding: 10px;
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
        border-left: 8px solid;
    }
    .instance-block-title{
        width:100px;
        border-right:1px solid #DDDDDD;
        padding:5px;
        font-size:12px;
        font-family:'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight:500;
        display:flex;
        align-items:center;
        color:rgba(51,51,51,1);
        line-height:14px;
    }
    .instance-img{
        display: none;
        width:100px;
    }
    .instance-block-desc{
        padding-left: 20px;
        font-size:12px;
        font-family:'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight:400;
        display:flex;
        align-items:center;
        width:80%;
        text-align:left;
        color:rgba(102,102,102,1);
        line-height:14px;
    }
}
@media screen and (min-width:1920px){
    .instance-block-1{
        padding:0 360px;
        padding-bottom: 130px;
    }
    .instance-block-title{
        width:200px;
        border-right:1px solid #DDDDDD;
        padding:0 30px;
        font-size:18px;
        font-family:'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight:500;
        display:flex;
        align-items:center;
        color:rgba(51,51,51,1);
        line-height:25px;
    }
    .instance-block-desc{
        padding:0 20px;
        font-size:16px;
        font-family:'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight:400;
        display:flex;
        align-items:center;
        width:55%;
        text-align:left;
        color:rgba(102,102,102,1);
        line-height:22px;
    }
    .instance-img{
        width:325px;
        height:200px;
    }
    .instance-block-1-title{
        font-size:24px;
        font-family:'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight:500;
        color:rgba(51,51,51,1);
        line-height:33px;
        text-align: left;
        padding-top: 30px;
    }
    .instance-block-1-body{
        display: flex;
        width: 100%;
        height: 100%;
        margin-top: 50px;
        height:260px;
        padding: 30px;
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
        border-left: 8px solid;
    }
}
</style>